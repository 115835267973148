/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TodayIn10Min_viewer = {
    readonly insight: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ContentPieceCardWrapper_contentPiece">;
            };
        }>;
    };
    readonly quickTake: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ContentPieceCardWrapper_contentPiece">;
            };
        }>;
    };
    readonly brief: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ContentPieceCardWrapper_contentPiece">;
            };
        }>;
    };
    readonly expandedNews: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ContentPieceCardWrapper_contentPiece">;
            };
        }>;
    };
    readonly " $refType": "TodayIn10Min_viewer";
};
export type TodayIn10Min_viewer$data = TodayIn10Min_viewer;
export type TodayIn10Min_viewer$key = {
    readonly " $data"?: TodayIn10Min_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TodayIn10Min_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContentPieceInterfaceNonNullEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContentPieceCardWrapper_contentPiece"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TodayIn10Min_viewer",
  "selections": [
    {
      "alias": "insight",
      "args": [
        {
          "kind": "Literal",
          "name": "contentPieceType",
          "value": "INSIGHT"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ContentPieceConnection",
      "kind": "LinkedField",
      "name": "contentPieces",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "contentPieces(contentPieceType:\"INSIGHT\",first:1)"
    },
    {
      "alias": "quickTake",
      "args": [
        {
          "kind": "Literal",
          "name": "contentPieceType",
          "value": "QUICK_TAKE"
        },
        (v0/*: any*/)
      ],
      "concreteType": "ContentPieceConnection",
      "kind": "LinkedField",
      "name": "contentPieces",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "contentPieces(contentPieceType:\"QUICK_TAKE\",first:1)"
    },
    {
      "alias": "brief",
      "args": [
        {
          "kind": "Literal",
          "name": "contentPieceTypes",
          "value": [
            "DAILY_BRIEF",
            "WEEKLY_BRIEF"
          ]
        },
        (v0/*: any*/)
      ],
      "concreteType": "ContentPieceConnection",
      "kind": "LinkedField",
      "name": "contentPieces",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "contentPieces(contentPieceTypes:[\"DAILY_BRIEF\",\"WEEKLY_BRIEF\"],first:1)"
    },
    {
      "alias": "expandedNews",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2
        }
      ],
      "concreteType": "ContentPieceConnection",
      "kind": "LinkedField",
      "name": "personalisedContentPieces",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "personalisedContentPieces(first:2)"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
(node as any).hash = '5e6791e164891ff2659a89c58064b21b';
export default node;
